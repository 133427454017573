export const deliverySettings = {
	title: "Configure delivery and integrations",
	description: "Integrate with a delivery partner and centralize your management in Zig.",
	integrateButton: "New integration",
	tabs: {
		management: "Integration management",
		integrate: "Integrate delivery",
		configuration: "Settings",
	},
	management: {
		newMerchantTitle: "Add new store",
		newMerchantButton: "Add store",
		labelInput: "Store ID",
		integrationDescription: "API integration",
		integrationDescriptionWithDate: "API integration - {{date}}",
		removeIntegration: "Remove integration",
		saveChanges: "Save changes",
		syncMenu: "Continue Sync",
		merchants: "Stores",
		active: "Active",
		awaitIntegration: "Waiting for integration",
		removeIntegrationModal: {
			button: "Remove integration",
			title: "Remove integration",
			description: "Are you sure you want to remove the integrations?",
			okText: "Confirm",
			cancelText: "Cancel",
		},
		emptyState: {
			title: "There's nothing here yet",
			description: "It looks like you don't have a delivery integrated yet.",
			button: "Integrate a Delivery",
		},
	},
	integrate: {
		steps: {
			systemLabel: "Select integration system",
			authLabel: "Authenticate integration",
			syncMenuLabel: "Sync menu",
			systemAndAuth: {
				titleSystemIntegration: "Integration system",
				titleAuth: "Authenticate with partner system",
				descriptionIFood: "Paste your API key provided by IFood.",
				descriptionAuthRappi: "Paste your API key provided by Rappi.",
				labelSelect: "Select who you want to integrate with",
				labelInput: "Store ID",
				placeholderInput: "Paste the code here",
				submitButton: "Continue with integration",
				buttonAddMore: "Add another store",
				avisoTitle: "We need authorization to integrate with Rappi",
				avisoDescription:
					"Send a request to our support team and we will get in touch to follow up with the integration process.",
				iFoodDialog: {
					title: "We need authorization to integrate",
					description:
						"To continue with the integration with iFood, your confirmation is required. To do this, you must access the system and generate an API key. When you copy the iFood key, return to continue with the integration.",
					cancelButton: "Cancel integration",
					openButton: "Open iFood",
				},
			},
			syncMenu: {
				title: "Soon you will be able to import your menu!",
				descriptionIFood:
					"In a few weeks you will be able to import your menu from iFood and use it also in our system.",
				descriptionRappi:
					"In a few weeks you will be able to import your menu from Rappi and use it also in our system.",
				syncOffMenuButton: "Finish integration without menu",
			},
		},
	},
};
