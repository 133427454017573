export const manualInvoiceImporting = {
	form: {
		num: "Número da nota",
		serie: "Série da nota",
		date: "Data da emissão",
		inputDate: "Data de entrada no estoque físico",
		operationType: "Natureza da operação",
		accessKey: "Chave de acesso",
		supplier: "Emitente",
		suppliers: "Fornecedores",
		newSupplier: "Novo fornecedor",
		totalValue: "Valor da nota",
		discountValue: "Descontos",
		finalValue: "Valor total",
		amountOfDuplicates: "Número de parcelas",
		firstDueDate: "Primeiro vencimento",
		duplicateInterval: "Intervalo entre as parcelas",
		duplicatesDetails: "Detalhes do parcelamento",
		duplicateNumber: "Número da parcela",
		dueDate: "Data de vencimento",
		value: "Valor",
		intervalTypes: {
			Month: "Por mês",
			Week: "Por semana",
		},
		shippingValue: "Frete",
		insuranceValue: "Seguro",
		otherExpensesValue: "Despesas acessórias",
		icmsValue: "ICMS",
		icmsstValue: "ICMS ST",
		ipiValue: "IPI",
		associatedProduct: "Produto equivalente",
		associatedChartAccount: "Plano de contas",
		count: "Quantidade",
		unitCostValue: "Custo unitário",
		totalCost: "Custo total",
		basicStorageUnit: "Unidade de Medida",
		success: "Importação feita com sucesso",
		storage: "Estoque",
		accessKeyInfo:
			// eslint-disable-next-line max-len
			"A chave de acesso da NF-e é composta por 44 dígitos numéricos que identificam a nota fiscal eletrônica. Ela inclui informações como o CNPJ do emitente, UF, ano e mês de emissão, número da nota, modelo, série, e código de controle único.",
	},
	sections: {
		inviceDetails: "Dados da nota fiscal",
		duplicates: "Pagamento parcelado",
		expenses: "Frete e despesas",
		taxes: "Impostos",
		products: "Produtos",
	},
	validations: {
		storageIsRequired: "É obirgatório selecionar um estoque.",
		duplicateInvoice: "Esta nota já foi importada.",
		supplierIsRequired: "É obrigatório selecionar um emitente.",
		numIsRequired: "É obrigatório informar o número da nota.",
		numIsInvalid: "Número da nota deve conter somente números.",
		serieIsInvalid: "Série deve conter somente números.",
		serieIsRequired: "É obrigatório informar a série da nota.",
		operationTypeIsRequired: "É obrigatório informar a natureza da operação.",
		totalValueIsRequired: "É obrigatório informar o valor total da nota.",
		productsIsRequired: "É obrigatório informar pelo menos um produto.",
		invalidAccessKey: "Chave de acesso inválida.",
	},
};
