import { deliverySettings } from "./settings";

export const delivery = {
	goBack: "Volver",
	updateManyExternalIntegrations: {
		success: "Integraciones actualizadas conxito",
	},
	externalStoreIntegrationStatus: {
		synced: "Sincronizado",
		pendingSyncing: "Sincronización pendiente",
		pendingImporting: "Importación pendiente",
	},
	deliverySettings,
};
