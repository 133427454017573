import { deliverySettings } from "./settings";

export const delivery = {
	goBack: "Back",
	updateManyExternalIntegrations: {
		success: "Integrations updated successfully",
	},
	externalStoreIntegrationStatus: {
		synced: "Synced",
		pendingSyncing: "Syncing pending",
		pendingImporting: "Importing pending",
	},
	deliverySettings,
};
