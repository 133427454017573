import { deliverySettings } from "./settings";

export const delivery = {
	goBack: "Retour",
	updateManyExternalIntegrations: {
		success: "Integrations mises jour avec succs",
	},
	externalStoreIntegrationStatus: {
		synced: "Synchronisé",
		pendingSyncing: "Synchronisation en attente",
		pendingImporting: "Importation en attente",
	},
	deliverySettings,
};
