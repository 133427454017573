export const bonusCategories = {
	title: "Bonus Categories",
	notification: {
		create: "Bonus category successfully created",
		edit: "Bonus category successfully edited",
		delete: "Bonus category successfully deleted",
		changeStatus: "Bonus category successfully changed",
	},
	delete: {
		title: "Delete Bonus Category",
		subtitle: "Are you sure you want to delete this bonus category?",
		confirm: "Yes, delete",
		cancel: "Cancel",
	},
	header: {
		create: "New Bonus Category",
		edit: "Edit Bonus Category",
		search: "Search for...",
	},
	list: {
		status: {
			label: "Status",
			active: "Active",
			inactive: "Inactive",
		},
		category: "Category",
		employees: "Authorized",
		daysToExpire: "Expiration",
		days: "days",
		trash: "Delete",
		options: {
			edit: "Edit Category",
			inactive: "Deactivate Category",
			active: "Activate Category",
		},
	},
	form: {
		name: {
			label: "Category Name",
			required: "Name is required",
			placeholder: "Enter the category name",
			maxLength: "Máximo de 30 caracteres",
		},
		daysToExpire: {
			label: "Bonus Validity",
			required: "Field is required",
			placeholder: "Days",
			positive: "Days to expiration must be a positive number",
			maxValue: "Maximum is 365 days",
		},
		employees: {
			label: "Authorized Employees",
			placeholder: "Select Employees",
			validation: "Select at least one employee",
		},
	},
};
