export const deliverySettings = {
	title: "Configurer le livraison et les intégrations",
	description:
		"Intégrez avec un partenaire de livraison et centralisez votre gestion dans Zig.",
	integrateButton: "Nouvelle intégration",
	tabs: {
		management: "Gestion des intégrations",
		integrate: "Intégrer la livraison",
		configuration: "Paramètres",
	},
	management: {
		newMerchantTitle: "Ajouter un nouveau magasin",
		newMerchantButton: "Ajouter un magasin",
		labelInput: "ID du magasin",
		integrationDescription: "Intégration via API",
		integrationDescriptionWithDate: "Intégration via API - {{date}}",
		removeIntegration: "Supprimer l'intégration",
		saveChanges: "Enregistrer les modifications",
		syncMenu: "Continuer la synchronisation",
		merchants: "Magasins",
		active: "Actif",
		awaitIntegration: "En attente d'intégration",
		removeIntegrationModal: {
			button: "Supprimer l'intégration",
			title: "Supprimer l'intégration",
			description: "Etes-vous sûr de vouloir supprimer l'intégration?",
			okText: "Confirmer",
			cancelText: "Annuler",
		},
		emptyState: {
			title: "Il n'y a encore rien ici",
			description: "Il semble que vous n'ayez pas encore intégré de livraison.",
			button: "Intégrer une Livraison",
		},
	},
	integrate: {
		steps: {
			systemLabel: "Sélectionner le système d'intégration",
			authLabel: "Authentifier l'intégration",
			syncMenuLabel: "Synchroniser le menu",
			systemAndAuth: {
				titleSystemIntegration: "Système d'intégration",
				titleAuth: "Authentification au système partenaire",
				descriptionIFood: "Collez ici votre clé API fournie par IFood.",
				descriptionAuthRappi: "Collez ici votre clé API fournie par Rappi.",
				labelSelect: "Sélectionnez avec qui nous allons intégrer",
				labelInput: "ID du magasin",
				placeholderInput: "Collez ici le code",
				submitButton: "Continuer avec l'intégration",
				buttonAddMore: "Ajouter un autre magasin",
				avisoTitle: "Nous avons besoin d'une autorisation pour intégrer avec Rappi",
				avisoDescription:
					"Envoyez une demande à notre service client et nous vous contacterons pour poursuivre le processus d'intégration.",
				iFoodDialog: {
					title: "Nous avons besoin d'une autorisation pour intégrer",
					description:
						"Pour poursuivre l'intégration avec iFood, votre confirmation de demande est nécessaire. Pour cela, vous devez accéder au système et générer une clé API. Lorsque vous copiez la clé iFood, revenez pour suivre l'intégration.",
					cancelButton: "Annuler l'intégration",
					openButton: "Ouvrir iFood",
				},
			},
			syncMenu: {
				title: "Bientôt vous pourrez importer votre menu!",
				descriptionIFood:
					"Dans quelques semaines, vous pourrez importer votre menu d'iFood et l'utiliser également dans notre système.",
				descriptionRappi:
					"Dans quelques semaines, vous pourrez importer votre menu de Rappi et l'utiliser également dans notre système.",
				syncOffMenuButton: "Conclure l'intégration sans menu",
			},
		},
	},
};
