export const hawkersReport = {
	title: "Hawkers Panel",
	description: "See the general performance of hawkers on site",
	general: {
		title: "General Panel",
		hawkerSelect: "Select a hawker",
		totalizers: {
			launchedProductsTotal: "Total products launched",
			returnedProductsTotal: "Total products returned",
			commissionTotal: "Total commissions",
			salesTotal: "Total sales",
		},
		table: {
			sales: "Sales",
			devolutions: "Refunds",
			paymentMethod: "Payment methods",
			productsRelease: "Product release",
			productsCancellation: "Product cancellation",
			noReturnNoSalesProducts: "Products no return and no sale",
			columns: {
				product: "Product",
				quantity: "Quantity",
				unitValue: "Unit value",
				total: "Total",
				commission: "Commission",
				paymentMethod: "Payment method",
				value: "Value",
			},
		},
	},
	summary: {
		title: "Summary by session and cycle",
		summary: "Summary",
		filterBreak: "Show only sessions with cash breaks",
		filterClosure: "Show only sessions with pending closure",
		columns: {
			session: "Session",
			hawkerName: "Hawker",
			hawkerDocument: "Document",
			cycleCount: "Cycles",
			change: "Change",
			receivedValue: "Received value",
			break: "Cash break",
			openedAt: "Opening",
			closedAt: "Opening",
			openClosure: "Open closure",
			cycle: "Cycle",
			previousBalance: "Previous balance",
			closingValue: "Closing value",
			balance: "Final balance",
			cashierNameOpener: "Cashier opening",
			cashierNameCloser: "Cashier closing",
		},
	},
	ranking: {
		title: "Ranking",
		filename: "ranking",
		columns: {
			name: "Name",
			document: "Document",
			commission: "Commission",
			total: "Total Sold",
		},
	},
	releases: {
		title: "Launches by hawkers",
		quantityProducts: "Qty of products",
		totalValueReleases: "Total value",
		filename: "hawker-record-{{hawker}}",
		hawkerRecord: "Hawker record",
		name: "Hawker name",
		columns: {
			hawkerName: "Name",
			hawkerDocument: "Document",
			total: "Total",
			productName: "Product",
			quantity: "Quantity",
			value: "Unit value",
		},
	},
	cashierClosing: {
		title: "Hawker cashier closing",
		name: "Name",
		document: "Document",
		begin: "Opening",
		end: "Closing",
		closingValue: "Closing value",
		financialResume: "Financial summary",
		resume: "Summary",
		value: "Value",
		products: "Products",
		product: "Product",
		quantity: "Quantity",
		unitValue: "Unit value",
		total: "Total",
		paymentMethods: "Payment methods",
		paymentMethod: "Payment method",
		date: "Date and time",
		changeEntries: "Change Entries",
		bleedings: "Bleedings",
		cycleClosings: "Cycle Closings",
		sessionClosings: "Session Closings",
		financialSummaryTitles: {
			changeFundsValue: "Change funds total",
			bleedingsValue: "Bleedings total",
			cyclesReceivedValue: "Cycle totalizer",
			sessionsReceivedValue: "Session totalizer",
			cashValue: "Cash value",
			valueWithCashier: "Amount held by cashier",
			cashierDiffValue: "Cashier closed with breakage/surplus",
		},
		diffValue: "Cashier break/Surplus",
	},
	filter: {
		filter: "Filter",
		clickToFilter: "Click to filter",
		employee: {
			label: "Employee",
			description: "Enter employee name",
			title: "Filter by employee",
		},
		hawkerName: {
			label: "Hawker name",
			description: "Enter vendor name",
			title: "Filter by vendor name",
		},
		hawkerDocument: {
			label: "Hawker document",
			description: "Enter vendor document",
			title: "Filter by vendor document",
		},
		cashierName: {
			label: "Cashier name",
			description: "Enter cashier name",
			title: "Filter by cashier name",
		},
		cashierDocument: {
			label: "Cashier document",
			description: "Enter cashier document",
			title: "Filter by cash document",
		},
	},
};
