export const bonusCategories = {
	title: "Categorias de bônus",
	notification: {
		create: "Categoria de bônus criada com sucesso",
		edit: "Categoria de bônus editada com sucesso",
		changeStatus: "Categoria de bônus alterada com sucesso",
		delete: "Categoria de bônus excluída com sucesso",
	},
	delete: {
		title: "Excluir Categoria de Bônus",
		subtitle: "Tem certeza que deseja excluir essa categoria de bônus?",
		confirm: "Sim, excluir",
		cancel: "Cancelar",
	},
	header: {
		create: "Nova Categoria de Bônus",
		edit: "Editar Categoria de Bônus",
		search: "Pesquisar por...",
		changeStatus: "Alterar Status",
		delete: "Excluir Categoria de Bônus",
	},
	list: {
		status: {
			label: "Status",
			active: "Ativo",
			inactive: "Inativo",
		},
		category: "Categoria",
		employees: "Autorizados",
		daysToExpire: "Expiração",
		days: "dias",
		trash: "Excluir",
		options: {
			edit: "Editar Categoria",
			inactive: "Inativar Categoria",
			active: "Ativar Categoria",
		},
	},
	form: {
		name: {
			label: "Nome da categoria",
			required: "Nome é obrigatório",
			placeholder: "Insira o nome da categoria",
			maxLength: "Máximo de 30 caracteres",
		},
		daysToExpire: {
			label: "Validade do bônus",
			required: "Campo obrigatório",
			placeholder: "Dias",
			positive: "Dias para expiração deve ser um número positivo",
			maxValue: "Máximo é 365 dias",
		},
		employees: {
			label: "Funcionários autorizados",
			placeholder: "Selecione Funcionários",
			validation: "Selecione pelo menos um funcionário",
		},
	},
};
