import { deliverySettings } from "./settings";

export const delivery = {
	goBack: "Voltar",
	updateManyExternalIntegrations: {
		success: "Integrações atualizadas com sucesso",
	},
	externalStoreIntegrationStatus: {
		synced: "Sincronizado",
		pendingSyncing: "Sincronização pendente",
		pendingImporting: "Importação pendente",
	},
	deliverySettings,
};
