export const financialReport = {
	label: "Resume",
	finished: {
		end: "Closed: {{end}}",
		not: "The event has not closed",
	},
	reportIn: "Billing for the event '{{event}}'",
	fileName: "billing-{{event}}",
	tabs: {
		bonusByProduct: {
			title: "Bonus per product",
			filename: "bonus_per_product",
			columns: {
				product: "Product",
				productCategory: "Product Category",
				bonusCategory: "Bonus Category",
				value: "Bonus received in the period",
				bonusSpentFromOtherPeriod: "Bonus received in other periods",
				receivedInPeriod: "Received in the period",
				receivedInOtherPeriod: "Received in other periods",
				total: "Total of the category",
				noCategory: "Without category",
			},
			cards: {
				totalProductsValue: "Total bonus in Products",
				totalTipValue: "Total bonus in Tip",
				totalValue: "Total",
				receivedInPeriod: "Received at event",
				receivedAtAnotherPeriod: "Received at other events",
			},
		},
		bonusReport: {
			title: "Bonus",
			description: "See all bonus granting and usage reports",
			filenameBonus: "bonus-granted",
			filenameAnticipated: "anticipated-consumption",
			filenameConsumption: "consumption",
			total: "Total of {{category}}",
			select: "Select a category",
			selectNotFound: "No category found",
			tabs: {
				given: "Bonus Granted",
				used: "Bonus Used",
			},
			columns: {
				client: "Client",
				category: "Category",
				date: "Date",
				reason: "Reason",
				receivedValue: "Received Value",
				spentValue: "Spent Value in the event",
				spentValueInOtherEvents: "Spent Value in other events",
				givenBy: "Given by",
				total: "Total",
			},
		},
	},
};
