import { ImportTranslations } from "./import-pt-br";

/* eslint-disable max-len */
export const IMPORT_FR_FR: ImportTranslations = {
	title: "Importation XML",
	invoiceData: {
		title: "Données de la facture",
		billNumber: "Numéro de facture",
		noteSeries: "Série de la facture",
		natureOfOperation: "Nature de l'opération",
		accesskey: "Clé d'accès",
		issuer: "Émetteur",
		CNPJAndCPF: "CNPJ/CPF",
		noteValue: "Valeur de la facture",
		discount: "Remise",
		totalValue: "Valeur totale",
		dateInputLabel: "Date d'entrée en stock_physique:",
	},
	duplicates: {
		title: "Duplicatas",
		duplicate: "Duplicata",
		dueDate: "Date d'échéance",
		value: "Valeur",
	},
	payment: {
		title: "Paiement",
		cashPayment: "Paiement au comptant",
		installmentsPayment: "Paiement échelonné",
		payment: "Paiement",
	},
	ShippingAndExpenses: {
		title: "Frais et dépenses",
		shipping: "Frais de port",
		insurance: "Assurance",
		additionalExpenses: "Dépenses supplémentaires",
	},
	infoCalculator:
		"La base du calcul du coût unitaire est : Valeur totale - Remise + IPI + ICMS + Frais de port + Assurance + Autres dépenses / Quantité",
	product: {
		amount: "Quantité",
		commercialUnit: "Unité de mesure",
		unitValue: "Prix par unité",
		equivalentProduct: "Produit équivalent",
		chartOfAccounts: "Plan comptable",
		selectChartOfAccounts: "Sélectionnez un plan comptable",
		noChartOfAccounts: "Aucun plan comptable disponible",
		equivalentTo: "1 équivaut à",
		unitCost: "Coût unitaire",
		totalCost: "Coût total",
		missingProductWarning:
			"Cet article n'est pas enregistré, vous devez l'enregistrer avant de continuer.",
	},
	invoiceDetailsFooterButtons: {
		cancel: "Annuler",
		next: "Suivant",
	},
	updateInvoiceData: {
		successfullyUpdated: "Données de la facture mises à jour avec succès",
	},
	newProductModal: {
		triggerText: "Cliquez ici pour l'enregistrer.",
		modalTitle: "Ajouter un article",
		contentText: "Que voulez-vous enregistrer?",
		insumo: {
			title: "Ingrédient",
			description:
				"Peuvent être utilisés comme ingrédients dans les recettes d'autres produits et n'apparaissent pas dans vos points de vente.",
			action: "Cliquez pour créer un nouvel ingrédient",
		},
		produto: {
			title: "Produit",
			description:
				"Produits finis de vente qui peuvent utiliser, ou non, des ingrédients dans leur production.",
			action: "Cliquez pour créer un nouveau produit",
		},
	},
};
