import {
	CheckedBy,
	ClosingEventConferenceStatus,
	Event,
	EventState,
	ImageUrl,
	Place,
} from "#api/enterprise-generated";
import PlaceStore from "#stores/place";
import { EventStore } from "#stores/event";
import { extendModel } from "#helpers/mobx/utils";

/**  @deprecated models should not ne used anymore */
export class EventModel implements Event {
	public eventStore: EventStore;
	public placeStore: PlaceStore;

	public id!: string;
	public name!: string;
	public description!: string | null;
	public place!: Place | null;
	public status!: EventState;
	public begin!: Date;
	public end!: Date | null;
	public cover!: ImageUrl | null;
	public image!: ImageUrl | null;
	public attractions!: string[] | null;
	public closingConferenceStatus!: ClosingEventConferenceStatus | null;
	public passTemplateId!: string | null;
	public openedBy!: string | null;
	public openedAt!: Date | null;
	public checkedAt!: Date | null;
	public checkedBy!: CheckedBy | null;

	constructor(
		eventStore: EventStore,
		placeStore: PlaceStore,
		event: Event,
		placeId: string,
	) {
		this.eventStore = eventStore;
		this.placeStore = placeStore;
		this.sync(event, placeId);
	}

	public sync = (event: Event, placeId: string) => {
		let { place, ...eventWithoutPlace } = event;
		//TODO
		place = this.placeStore.places.find(p => p.id === placeId) || this.placeStore.place;
		extendModel(this, eventWithoutPlace);
	};

	public open = (updateDate?: boolean) => {
		this.eventStore.openEvent(this.id, updateDate);
	};

	public close = () => {
		this.eventStore.closeEvent(this.id);
	};

	public delete = () => {
		this.eventStore.deleteEvent(this.id);
	};
}
