export const hawkersReport = {
	title: "Painel Ambulantes",
	description: "Veja a performance geral dos ambulantes no local",
	general: {
		title: "Painel Geral",
		hawkerSelect: "Selecione um ambulante",
		totalizers: {
			launchedProductsTotal: "Total de produtos lançados",
			returnedProductsTotal: "Total de produtos devolvidos",
			commissionTotal: "Total em comissões",
			salesTotal: "Total em vendas",
		},
		table: {
			sales: "Vendas",
			devolutions: "Devoluções",
			paymentMethod: "Métodos de pagamento",
			productsRelease: "Lançamento de produtos",
			productsCancellation: "Cancelamento de produtos",
			noReturnNoSalesProducts: "Produtos sem devolução e sem venda",
			columns: {
				product: "Produto",
				quantity: "Quantidade",
				unitValue: "Valor unitário",
				total: "Total",
				commission: "Comissão",
				paymentMethod: "Método de pagamento",
				value: "Valor",
			},
		},
	},
	summary: {
		title: "Resumo por sessão e ciclo",
		summary: "Resumo",
		filterBreak: "Mostrar apenas sessões com quebras",
		filterClosure: "Mostrar apenas sessões com fechamento pendente",
		columns: {
			session: "Sessão",
			hawkerName: "Ambulante",
			pub: "Bar",
			hawkerDocument: "Documento",
			cycleCount: "Ciclos",
			change: "Troco",
			receivedValue: "Valor recebido",
			break: "Quebra de caixa",
			openedAt: "Abertura",
			closedAt: "Fechamento",
			openClosure: "Fechamento em aberto",
			cycle: "Ciclo",
			previousBalance: "Saldo anterior",
			closingValue: "Valor fechamento",
			balance: "Saldo final",
			cashierNameOpener: "Abertura caixa",
			cashierNameCloser: "Fechamento caixa",
		},
	},
	ranking: {
		title: "Ranking",
		filename: "ranking",
		columns: {
			name: "Nome",
			document: "Documento",
			commission: "Comissão",
			total: "Total Vendido",
		},
	},
	releases: {
		title: "Lançamentos por ambulantes",
		quantityProducts: "Qtd de produtos",
		totalValueReleases: "Valor total",
		filename: "ficha-ambulante-{{hawker}}",
		hawkerRecord: "Ficha Ambulante",
		name: "Nome do ambulante",
		columns: {
			hawkerName: "Nome",
			hawkerDocument: "Documento",
			total: "Total",
			productName: "Produto",
			quantity: "Quantidade",
			value: "Valor unitário",
		},
	},
	cashierClosing: {
		title: "Fechamento de caixa de ambulante",
		name: "Nome",
		document: "Documento",
		begin: "Abertura",
		end: "Fechamento",
		closingValue: "Valor fechamento",
		financialResume: "Resumo financeiro",
		resume: "Resumo",
		value: "Valor",
		products: "Produtos",
		product: "Produto",
		quantity: "Quantidade",
		unitValue: "Valor unitário",
		total: "Total",
		paymentMethods: "Métodos de pagamento",
		paymentMethod: "Método de pagamento",
		date: "Data e hora",
		changeEntries: "Lançamentos de troco",
		bleedings: "Sangrias",
		cycleClosings: "Fechamentos de ciclos",
		sessionClosings: "Fechamentos de sessões",
		financialSummaryTitles: {
			changeFundsValue: "Total de troco",
			bleedingsValue: "Total de sangria",
			cyclesReceivedValue: "Totalizador de ciclos",
			sessionsReceivedValue: "Totalizador de sessões",
			cashValue: "Valor em dinheiro",
			valueWithCashier: "Valor em posse do caixa",
			cashierDiffValue: "Caixa fechado com quebra/sobra",
		},
		diffValue: "Quebra/Sobra de caixa",
	},
	filter: {
		filter: "Filtrar",
		clickToFilter: "Clique pra filtrar",
		employee: {
			label: "Funcionário",
			description: "Digite o nome do funcionário",
			title: "Filtrar por funcionário",
		},
		hawkerName: {
			label: "Nome do ambulante",
			description: "Digite o nome do ambulante",
			title: "Filtrar por nome do ambulante",
		},
		hawkerDocument: {
			label: "Documento do ambulante",
			description: "Digite o documento do ambulante",
			title: "Filtrar por documento do ambulante",
		},
		cashierName: {
			label: "Nome do caixa",
			description: "Digite o nome do caixa",
			title: "Filtrar por nome do caixa",
		},
		cashierDocument: {
			label: "Documento do caixa",
			description: "Digite o documento do caixa",
			title: "Filtrar por documento do caixa",
		},
	},
};
