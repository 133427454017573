import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { pt as commonPt } from "./translations/pt-BR";
import { es_MX as commonEs } from "./translations/es-MX";
import { en_US as commonEn } from "./translations/en-US";
import { fr as commonFr } from "./translations/fr-FR";
import { DEFAULT_LANGUAGE } from "./utils";

export const resources = {
	"pt-BR": commonPt,
	"es-MX": commonEs,
	"es-ES": commonEs,
	"en-US": commonEn,
	"fr-FR": commonFr,
} as const;

export const defaultNS = "place";

i18n.use(initReactI18next).init({
	fallbackLng: {
		default: [DEFAULT_LANGUAGE],
		"fr-FR": ["en-US"],
		"es-MX": ["pt-BR"],
		"es-ES": ["pt-BR"],
	},
	interpolation: {
		escapeValue: false,
	},
	ns: [
		"place",
		"globalKeys",
		"org",
		"event",
		"nav",
		"components",
		"store",
		"noAuth",
		"backoffice",
	],
	defaultNS,
	resources,
	react: {
		transSupportBasicHtmlNodes: true,
		transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span"],
	},
});

export default i18n;
