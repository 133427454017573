export const newAndEditProduct = {
	titles: {
		edit: "Editar Produto",
		add: "Adicionar Produto",
		productDetails: "Detalhes do produto",
		hasFiscalData: "Dados fiscais",
		hasMountableProduct: "Produto montável",
		hasProductRule: "Ficha Técnica",
		hasProportionalProduct: "Produto em g/ml",
		downloadQRCodeToSell: "Baixar QR code para venda",
		releaseValue: "Preço de solta",
	},
	duplicateButton: "Duplicar",
	errors: {
		productFeature: "A funcionalidade {{wrongFeature}} não está preenchida corretamente.",
		productFeatures:
			"As funcionalidades {{wrongFeatures}} não estão preenchidas corretamente.",
		requiredField: "Esse campo é obrigatório",
		proportionalQuantity: "A quantidade deve ser maior que 0",
		abvQuantity: "O percentual deve ser menor que 100%",
		name: "Use apenas caracteres válidos",
		categoryId: "Escolha ou crie uma categoria",
		wrongSection: "A Etapa {{wrongStep}} não está preenchida corretamente.",
		wrongSections: "As Etapas {{wrongSteps}} não estão preenchidas corretamente.",
		productRules: "Existem insumos não preenchidos",
		newCategory: "Você criará uma nova categoria",
		length: "Máx. {{length}} caracteres",
		fiscalCode: "Apenas números, pontos e letras",
	},
	forms: {
		details: {
			name: "Nome do Produto",
			type: "Tipo do Produto",
			selectType: "Selecione um tipo de produto",
			measurement: "Unidade de Medida",
			category: "Categoria",
			menuCategory: "Categoria do Menu",
			priceIn: "Preço em",
			price: "Preço",
			variablePrice: "Preço variável",
			picture: "Foto",
			description: "Descrição",
			hasAlcohol: "Contém Álcool",
			hasFiscalData: "Dados fiscais",
			fiscalDataMessage: "O código do produto será gerado automaticamente",
			hasMountableProduct: "Produto montável",
			isStockable: "Produto estocável",
			hasProductRule: "Ficha Técnica",
			hasProportionalProduct: "Produto em g/ml",
			isHiddenFromApp: "Não exibir produto no aplicativo ZigApp",
			syncWithBeerIntegration: "Sincronizar com {{integration}}",
			beerBrand: "Marca",
			beerStyle: "Estilo da bebida",
			beerABV: "ABV - Teor alcoólico",
			beerIbu: "IBU - Unidades Internacionais de Amargor",
			beerIntegrationDisabledWarning:
				"Não é possível desativar essa função com 'Sincronizar com {{integration}}' ativa",
			beerIntegrationEnabledWarning:
				"Não é possível ativar essa função com 'Sincronizar com {{integration}}' ativa",
			beerIntegrationPlaceFeatureWarning:
				"Função desabilitada. Entre em contato com o suporte para ativa-la no admin.",
			productReturn: "Produto retornável",
			isSupplyToggle: "Insumo",
			convertionRule: {
				unit: "Unidade",
				amount: "Quantidade",
				unitMeansure: "Unidade de medida",
				placeholderSelect: "Selecione",
				convertionName: "Regra de conversão",
				title: {
					unitDefault: "Unidade de medida padrão",
					unitConvertion: "Regra de conversão",
				},
				field: { quantity: "Quantidade", unitMeansure: "Unidade de medida" },
				resumeDescription: "de <span>{{productName}}</span> equivale a",
				infoConvertionRule:
					"Cria uma segunda unidade medida para seu produto possibilitando a movimentação do mesmo de diferentes formas em seu estoque.",
				warningConvertionRule:
					"A regra de conversão só pode ser habilitada em insumos quando a Unidade de medida selecionada for diferente de unidade.",
			},
		},
		fiscal: {
			code: "SKU",
			codeErrorMessage: "Caso não seja preenchido, o código será gerado automaticamente",
			ncm: "NCM",
			cest: "CEST",
			fiscalProfile: "Perfil fiscal",
			fiscalGroup: "Grupo fiscal",
		},
		mountable: {
			step: "Etapa",
			addStep: "Adicionar Etapa",
			infos: {
				label: "Informações",
				title: "Título",
				titleErrorMessage: "Nome inválido",
				precification: "Precificação",
				precificationErrorMessage: "Escolha um método",
				precificationSelect: {
					label: "Escolha...",
					sum: "Adicional",
					sumTooltip: "Todo item adicionado será incrementado no valor do produto",
					average: "Média",
					averageTooltip: "A média dos itens selecionados será incrementada",
					higher: "Maior valor",
					higherTooltip:
						"O maior valor entre os itens será adicionado ao valor do produto",
				},
				min: "Min",
				tooltipMin: "Mínimo de itens a ser selecionado.",
				max: "Max",
				tooltipMax: "Máximo de itens a ser selecionado.",
				invalidValue: "Valor inválido",
				validateMaxMinErrors: {
					maxValueZero: "O valor máximo não pode ser igual à zero",
					negativeValue: "Não é permitido valor negativo",
					maxBiggerThenMin: "Valor máximo deve ser maior que o valor mínimo",
				},
			},
			items: {
				label: "Itens",
				title: "Item",
				titleErrorMessage: "Nenhum item selecionado",
				value: "Valor",
				valueErrorMessage: "Valor adicional inválido",
				maxQuantity: "Quantidade máx selecionável",
				maxQuantityTooltip:
					"Quantidade máxima de vezes que este item pode ser selecionado na seção",
				storageCount: "Quantidade abatida",
				storageCountErrorMessage: "Quantidade inválida",
				storageCountTooltip: "Quantidade que será abatida do estoque por unidade.",
				additionalItems: "Adicionar outro item",
				itemKey: "Item {{itemKey}}",
			},
			information: "INFORMAÇÕES",
			itemsTitle: "ITENS",
			getTitleDialog: {
				disable: "Desativar item montável",
				activate: "Ativar item montável",
			},
			getDescriptionDialog: {
				disable:
					"Deseja desativar esse item nos demais produtos montáveis que ele compõe?",
				activate: "Deseja ativar esse item nos demais produtos montáveis que ele compõe?",
			},
		},
		productRule: {
			generatedQuantity: "Quantidades geradas do produto",
			input: "Insumo",
			quantity: "Quantidade",
			loss: "Perda",
			addInput: "Adicionar outro insumo",
		},
		proportionalValue: {
			measurement: "Unidade mensurável",
			quantity: "Quantidade",
			grams: "Gramas",
			mililiters: "Mililitros",
		},
	},
	cancelButton: "Cancelar",
	confirmButton: "Confirmar",
	undefined: "indefinido",
};
