import * as React from "react";
import { Dayjs } from "dayjs";
import dayJsGenerateConfig from "rc-picker/lib/generate/dayjs";
import { RangeValue as RangeValueRcPicker } from "rc-picker/lib/interface";
import generatePicker, {
	PickerProps,
	RangePickerProps as RangePickerPropsUi,
} from "antd/lib/date-picker/generatePicker";

import "./date-picker.scss";

export type DatePickerAbsProps = PickerProps<Dayjs>;

export type RangeValue<DateType> = RangeValueRcPicker<DateType>;

export type RangePickerProps = RangePickerPropsUi<Dayjs> & {
	shouldAdjustRange?: boolean;
};

const CustomDatePicker = generatePicker<Dayjs>(dayJsGenerateConfig);

export function DatePicker({ ...props }: DatePickerAbsProps) {
	return <CustomDatePicker {...props} />;
}

function RangePicker({
	value,
	onChange,
	onCalendarChange: onCalendarChangeProp,
	shouldAdjustRange = true,
	...props
}: RangePickerProps) {
	// const [isSelecting, setIsSelecting] = React.useState(false);

	return (
		<CustomDatePicker.RangePicker
			value={value}
			onCalendarChange={(period, formatString, info) => {
				if (!period) return;
				/**
				 * validation and casting is necessary because changeFn expects
				 * a tuple of two values, instead of an array
				 * */
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				if (period.length !== 2) return;
				if (!period.every(Boolean)) return;

				const changeFn = onCalendarChangeProp ?? onChange;
				if (!changeFn) return;

				const asserted = period as [Dayjs, Dayjs];
				const normalized = shouldAdjustRange
					? (asserted.map((date, i) => {
							date = i === 0 ? date.startOf("day") : date.endOf("day");
							return date;
					  }) as [Dayjs, Dayjs])
					: asserted;

				changeFn(normalized, formatString, info);
			}}
			{...props}
		/>
	);
}

DatePicker.RangePicker = RangePicker;
DatePicker.TimePicker = CustomDatePicker.TimePicker;
DatePicker.WeekPicker = CustomDatePicker.WeekPicker;
DatePicker.MonthPicker = CustomDatePicker.MonthPicker;
DatePicker.QuarterPicker = CustomDatePicker.QuarterPicker;
DatePicker.YearPicker = CustomDatePicker.YearPicker;
