export const deliverySettings = {
	title: "Configurer le livraison et les intégrations",
	description:
		"Intégrez avec un partenaire de livraison et centralisez votre gestion dans Zig.",
	integrateButton: "Nouvelle intégration",
	tabs: {
		management: "Gestion des intégrations",
		integrate: "Intégrer la livraison",
		configuration: "Ajustes",
	},
	management: {
		newMerchantTitle: "Agregar tienda nueva",
		newMerchantButton: "Agregar tienda",
		labelInput: "ID de la tienda",
		integrationDescription: "Integración por API",
		integrationDescriptionWithDate: "Integración por API - {{date}}",
		removeIntegration: "Eliminar integración",
		saveChanges: "Guardar cambios",
		syncMenu: "Sincronizar menú",
		merchants: "Tiendas",
		active: "Activo",
		awaitIntegration: "Esperando integración",
		removeIntegrationModal: {
			button: "Eliminar integración",
			title: "Eliminar integración",
			description: "¿Está seguro de que desea eliminar las integraciones?",
			okText: "Confirmar",
			cancelText: "Cancelar",
		},
		emptyState: {
			title: "Todavía no hay nada aquí",
			description: "Parece que aún no tienes un delivery integrado.",
			button: "Integrar un Delivery",
		},
	},
	integrate: {
		steps: {
			systemLabel: "Seleccionar sistema de integración",
			authLabel: "Autenticar integración",
			syncMenuLabel: "Sincronizar menú",
			systemAndAuth: {
				titleSystemIntegration: "Sistema de integración",
				titleAuth: "Autenticación en el sistema asociado",
				descriptionIFood: "Pegue aquí su Clave API proporcionada por IFood.",
				descriptionAuthRappi: "Pegue aquí su Clave API proporcionada por Rappi.",
				labelSelect: "Seleccione con quién vamos a integrar",
				labelInput: "ID de la tienda",
				placeholderInput: "Pegue aquí el código",
				submitButton: "Continuar con la integración",
				buttonAddMore: "Añadir otra tienda",
				avisoTitle: "Necesitamos autorización para integrar con Rappi",
				avisoDescription:
					"Envíe una solicitud a nuestro soporte y nos pondremos en contacto para continuar con el proceso de integración.",
				iFoodDialog: {
					title: "Necesitamos autorización para integrar",
					description:
						"Para continuar con la integración con iFood, se requiere su confirmación de solicitud. Para ello, debe acceder al sistema y generar una Clave API. Cuando copie la Clave de iFood, regrese para continuar con la integración.",
					cancelButton: "Cancelar integración",
					openButton: "Abrir iFood",
				},
			},
			syncMenu: {
				title: "¡Pronto podrá importar su menú!",
				descriptionIFood:
					"En unas semanas podrá importar su menú de iFood y usarlo también en nuestro sistema.",
				descriptionRappi:
					"En unas semanas podrá importar su menú de Rappi y usarlo también en nuestro sistema.",
				syncOffMenuButton: "Concluir integración sin menú",
			},
		},
	},
};
