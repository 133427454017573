import {
	BasicStorageUnit,
	BeerProduct,
	ImageUrl,
	MountableDescriptionV2,
	PlaceProduct,
	PlaceProductMenuCategory,
	ProductBarEntry,
	ProductKind,
	ProportionalValue,
	ProportionOfUnit,
	TranslatedPlaceProductFiscalData,
} from "#api/enterprise-generated";
import { observable } from "mobx";
import { extendModel } from "#helpers/mobx/utils";
import { ItemStore } from "#stores/item";

/**  @deprecated models should not ne used anymore */
export type ItemType = "Product" | "Supply" | "Entrance" | "Couvert" | "Tip" | "Combo";

/**  @deprecated models should not ne used anymore */
export class ItemModel implements PlaceProduct {
	@observable
	public id!: string;
	@observable
	public name!: string;
	@observable
	public category!: string;
	@observable
	public description!: string;
	@observable
	public categoryId!: string;
	@observable
	public menuCategory: PlaceProductMenuCategory | null = null;
	@observable
	public value!: number;
	@observable
	public image!: ImageUrl | null;
	@observable
	public bars!: ProductBarEntry[];
	@observable
	public active!: boolean;
	@observable
	public hasAlcohol!: boolean | null;
	@observable
	public fiscalData!: TranslatedPlaceProductFiscalData;
	@observable
	public type!: ItemType;
	@observable
	public minimumConsumptionValue!: number | null;
	@observable
	public hasProductionRule!: boolean;
	@observable
	public proportionalValue: ProportionalValue | null = null;
	@observable
	public fiscalCode: string | null = null;
	@observable
	public mountableDescription: MountableDescriptionV2 | null = null;
	@observable
	public kind!: ProductKind;
	@observable
	public basicStorageUnit!: BasicStorageUnit;
	@observable
	public isHiddenFromApp!: boolean;
	@observable
	public beerProduct!: BeerProduct | null;
	@observable
	public yieldPercentage!: number | null;
	@observable
	public isReturnableProduct!: boolean | null;
	@observable
	public isStockable!: boolean;
	@observable
	public releaseValue: number | null = null;
	@observable
	public proportionOfUnit: ProportionOfUnit | null = null;

	public itemStore!: ItemStore;

	constructor(itemStore: ItemStore, item: PlaceProduct) {
		this.itemStore = itemStore;
		this.sync(item);
	}

	public sync = (item: PlaceProduct) => {
		if (!item.id) {
			throw new Error(
				`Item model created without an id:\n${JSON.stringify(item, undefined, 4)}`,
			);
		}
		extendModel(this, item);
	};
}
